import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  DynamicFormControlComponent,
  DynamicFormControlCustomEvent,
  DynamicFormControlLayout,
  DynamicFormLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from "@ng-dynamic-forms/core";
import { MyCustomFileUploadFormControlComponent } from "./my-custom-file-upload-form-control.component";
import { DynamicMyCustomFileUploadModel } from "../models/my-custom-file-upload.model";

@Component({
  selector: "my-dynamic-custom-file-upload-form-control",
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <ng-container [formGroup]="group">
      <my-custom-form-control
        [formControlName]="model.id"
        [id]="bindId ? model.id : null"
        [name]="model.name"
        [ngClass]="[
          getClass('element', 'control'),
          getClass('grid', 'control')
        ]"
        (blur)="onBlur($event)"
        (change)="onChange($event)"
        (focus)="onFocus($event)"
      >
      </my-custom-form-control>
    </ng-container>
  `,
})
export class MyDynamicCustomFIleUploadFormControlComponent extends DynamicFormControlComponent {
  @Input() bindId: boolean = true;
  @Input() group: FormGroup;
  @Input() layout: DynamicFormControlLayout;
  @Input() model: DynamicMyCustomFileUploadModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output()
  customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  @ViewChild(MyCustomFileUploadFormControlComponent, { static: false })
  myCustomFormControlComponent: MyCustomFileUploadFormControlComponent;

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }
}

import {
  DynamicSelectModel,
  DynamicFormControlLayout,
  DynamicSelectModelConfig,
  serializable,
  DynamicFormControlModel,
} from '@ng-dynamic-forms/core';
import { FormGroup } from '@angular/forms';
import { Output, EventEmitter, Component, Inject } from '@angular/core';
import { ForeignFieldConfig } from '../../django-form-iface';

export const DYNAMIC_FORM_CONTROL_TYPE_FILE_UPLOAD = 'FK_SELECTOR';

export interface DynamicMyCustomModelConfig<T>
  extends DynamicSelectModelConfig<T> {}

// @Component({
//   template: "",
// })
export class DynamicCustomFKSelectorModel extends DynamicSelectModel<any> {
  @serializable() readonly type: string = 'FK-SELECTOR';
  public ext_config: any;
  public config: any;
  public formGroup: FormGroup;
  formModel: DynamicFormControlModel[] = [];
  //  console.log("tony is "+fieldConfig['autocomplete_url'])

  // @Output() onReload = new EventEmitter();

  constructor(
    ex_config: any,
    formGroup: FormGroup,
    formModel: DynamicFormControlModel[],
    config: DynamicMyCustomModelConfig<any>,
    layout?: DynamicFormControlLayout,
  ) {
    super(config, layout);
    this.ext_config = ex_config;
    this.config = config;
    this.formGroup = formGroup;
    this.formModel = formModel;
  }
}

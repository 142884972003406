import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { config } from './config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { LoginService } from './services/login.service';
import { BASE_URL } from './shared/constants';
export interface Person {
  id: any;
  auto_complete_display_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient, private loginService: LoginService) {}

  getUsers() {
    return this.http.get('https://jsonplaceholder.typicode.com/users');
  }
  getApiItem(url: string) {
    // this.syncRights();
    return this.http.get(url);
  }
  getUserProfile() {
    // this.syncRights();
    return this.http.get(`${config.apiRootUrl}current-user/`);
  }

  getData(
    url: string,
    filter = '',
    sortOrder = 'asc',
    pageNumber = 0,
    pageSize = 10,
  ): Observable<any> {
    // this.syncRights();

    return this.http.get(config.apiRootUrl + url, {
      params: new HttpParams()
        .set('search', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', (pageNumber + 1).toString())
        .set('per_page', pageSize.toString()),
    });
  }

  autoCompleteCall(url: string): Observable<any> {
    // this.syncRights();
    return this.http.get(url);
  }

  autoCompleteCallPost(url: string): Observable<any> {
    // this.syncRights();
    return this.http.post(url, {});
  }
  autoCompleteCall1(url: string): Observable<any> {
    // this.syncRights();

    let headers = new HttpHeaders();
    headers = headers.set('AUTOCOMPLETE', 'AB');
    headers.set(
      'Access-Control-Allow-Methods',
      'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    );
    return this.http.get(url, {});
  }

  syncRights() {
    const user = this.loginService.getLoggedInUser();
    // check user role or login
    //  console.log(req.url)
    this.http
      .get(
        BASE_URL +
          '/users/' +
          this.loginService.getLoggedInUser().userId +
          '/rights/',
      )
      .subscribe((res) => {
        //  console.log(res)
        user.rights = res;

        localStorage.setItem('currentUser', JSON.stringify(user));
      });
  }

  downloadPdf(url) {
    // this.syncRights();
    const headers = new HttpHeaders();
    // headers = headers.set("Accept", "application/pdf");
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  downloadFile(url) {
    // this.syncRights();
    const headers = new HttpHeaders();
    // headers = headers.set("Accept", "application/pdf");
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  downloadExcel(url) {
    // this.syncRights();
    let headers = new HttpHeaders();
    headers = headers.set(
      'Accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    return this.http.get(url, { headers: headers, responseType: 'blob' });
  }

  // downloadFile(url): Observable<Blob> {
  //   let headers = new HttpHeaders();
  //   return this.http
  //     .get(url, { headers: headers, responseType: "blob" })
  //     .map((res) => res.blob())
  //     .catch(this.handleError);
  // }

  findData(
    url: string,
    filter = '',
    sortOrder = 'asc',
    pageNumber = 1,
    pageSize = 6,
  ): Observable<any> {
    // this.syncRights();

    if (pageNumber == 0) {
      pageNumber = 1;
    }
    return this.http
      .get(config.apiRootUrl + url, {
        params: new HttpParams()
          .set('search', filter)
          .set('sortOrder', sortOrder)
          .set('pageNumber', pageNumber.toString())
          .set('per_page', pageSize.toString()),
      })
      .pipe(map((res) => res));
  }

  deleteItem(url: string) {
    return this.http.delete(url);
  }

  postData(url: string, params: any): Observable<any> {
    // this.syncRights();
    return this.http.post(url, params).pipe(map((res) => res));
  }
}

import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DataService } from "../../../../data.service";
import { DynamicCustomFKSelectorModel } from "../models/custom-fk-selector.model";

@Component({
  selector: "custom-fk-selector-form-control",
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFKSelectorFormControlComponent),
      multi: true,
    },
  ],

  template: `
    <ng-select
      [items]="people$"
      [multiple]="is_multiple"
      bindLabel="auto_complete_display_name"
      bindValue="id"
      (change)="onValChange(selectedSimpleItem)"
      (search)="onItemSearch($event)"
      [(ngModel)]="selectedSimpleItem"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label">
          {{ item.auto_complete_display_name }}</span
        >
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ item.auto_complete_display_name }}
      </ng-template>
    </ng-select>
  `,
})
export class CustomFKSelectorFormControlComponent
  implements ControlValueAccessor {
  // <ng-select [items]="people$"

  // bindLabel="auto_complete_display_name"
  // bindValue="id"
  // [multiple]=is_multiple
  // [(ngModel)]="selectedSimpleItem"

  // (change)=onValChange(selectedSimpleItem)

  // (search)=onItemSearch($event)
  // >

  // </ng-select>

  //    <ng-select
  //     [items]="people$ | async"
  //     [multiple]="is_multiple"
  //     bindLabel="auto_complete_display_name"
  //     bindValue="id"
  //     [(ngModel)]="selectedSimpleItem">

  //     <ng-template ng-label-tmp let-item="item" let-clear="clear">
  //         <span class="ng-value-label"><img [src]="item.avatar_url" width="20px" height="20px"> {{item.auto_complete_display_name}}</span>
  //         <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  //     </ng-template>

  //     <ng-template ng-option-tmp let-item="item">
  //         <img [src]="item.avatar_url" width="20px" height="20px"> {{item.auto_complete_display_name}}
  //     </ng-template>
  // </ng-select>

  people$: any[];
  is_multiple: boolean = false;
  selectedSimpleItem: any;

  @Input()
  name: string;

  @Input()
  model: DynamicCustomFKSelectorModel;

  propagateChange: any;

  constructor(
    private dataService: DataService,
    private formService: DynamicFormService
  ) {}
  ngOnInit() {
    // console.log("tony....")
    // console.log(this.model.ext_config['autocomplete_url'])

    this.is_multiple = this.model.config.multiple;

    this.dataService
      .autoCompleteCall1(this.model.ext_config["autocomplete_url"])

      .subscribe((res) => {
        this.people$ = this.merge(this.people$, res["items"]);

        // console.log("tony....")
      });
  }

  changeListener($event): void {}

  onReload($event) {
    // console.log($event)
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value) {
    if (value) {
      this.dataService
        .autoCompleteCall(
          this.model.ext_config["autocomplete_url"] + "?ids=" + value
        )
        .subscribe((res) => {
          this.people$ = this.merge(this.people$, res["items"]);
          // this.model.
          this.formService.detectChanges();
        });
    }
    this.selectedSimpleItem = value;
    // this.propagateChange(this.selectedSimpleItem)
  }

  onValChange(val) {
    this.propagateChange(val);
  }

  onItemSearch($event) {
    this.dataService
      .autoCompleteCall(
        this.model.ext_config["autocomplete_url"] + "?query=" + $event.term
      )
      .subscribe((res) => {
        this.people$ = this.merge(this.people$, res["items"]);
      });
  }
  onItemAdded($event) {
    console.log($event);
  }
  merge(one, two) {
    if (!one) return two;
    if (!two) return one;
    var final = one;
    // merge
    var _new = [];
    for (var i = 0; i < two.length; i++) {
      var item = two[i];
      _new = _new.concat(this.insert(item, final));
    }
    final = final.concat(_new);
    return final;
  }
  insert(item, obj) {
    var data = obj;
    var insertIndex = data.length;

    var _add = true;
    for (var i = 0; i < data.length; i++) {
      if (item.id == data[i].id) {
        // ignore duplicates
        _add = false;
        break;
      }
    }
    if (_add) {
      return item;
    }
    return [];
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoggedUser, LogingResponceDTO } from "./dtd/loggedUser.dtd";
import { Router, RouteConfigLoadStart } from "@angular/router";
import { map } from "rxjs/operators";
import { BASE_URL, HOST_URL } from "../shared/constants";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(public http: HttpClient, private router: Router) {}

  loggedInUser: LoggedUser;

  login(reqBody) {
    return this.http.post(`${HOST_URL}api/v1/auth/login/`, reqBody).pipe(
      map((response: any) => {
        this.loggedInUser = {
          first_name: response.user.first_name,
          last_name: response.user.last_name,
          name: response.user.first_name + " " + response.user.last_name,
          userId: response.user.id,
          is_staff: response.user.is_staff,
          email: response.user.email,
          userName: response.user.username,
          profile: response.user.profile,
          position: response.user.profile.role,
          courier:response?.courier,
          expiration: 60 * 60 * 24 * 30 * 5,
          accessToken: response.token,
          refreshToken: response.token,
          staffId: response.hospitelStaff
            ? response.hospitelStaff.hospitalStaffId
            : null,
          patientId: response.patient ? response.patient.patientId : null,
          rights: response.user.adminstratorrights,
        };
        // if(response.hospitelStaff){
        //   this.loggedInUser.hospitalStaffId= response.hospitelStaff.hospitalStaffId;
        //   this.loggedInUser.position= response.hospitelStaff.position;
        //   this.loggedInUser.channels= response.hospitelStaff.channels;
        // }else{
        //   this.loggedInUser.position = "PATIENT"
        // }
        localStorage.setItem("currentUser", JSON.stringify(this.loggedInUser));
        return this.loggedInUser;
      })
    );
  }

  registerCustomer(reqBody) {
    return this.http.post(`${BASE_URL}/customers/`, reqBody).pipe(
      map((response: any) => {
        this.loggedInUser = {
          first_name: response.user.first_name,
          last_name: response.user.last_name,
          name: response.user.first_name + " " + response.user.last_name,
          userId: response.user.id,
          email: response.user.email,
          userName: response.user.username,
          profile: response.user.profile,
          position: response.user.profile.role,
          expiration: 60 * 60 * 24 * 30 * 5,
          accessToken: response.jwt,
          refreshToken: response.refreshToken,
          staffId: response.hospitelStaff
            ? response.hospitelStaff.hospitalStaffId
            : null,
          patientId: response.patient ? response.patient.patientId : null,
        };
        // if(response.hospitelStaff){
        //   this.loggedInUser.hospitalStaffId= response.hospitelStaff.hospitalStaffId;
        //   this.loggedInUser.position= response.hospitelStaff.position;
        //   this.loggedInUser.channels= response.hospitelStaff.channels;
        // }else{
        //   this.loggedInUser.position = "PATIENT"
        // }
        localStorage.setItem("currentUser", JSON.stringify(this.loggedInUser));
        return this.loggedInUser;
      })
    );
  }

  logOut() {
    // console.log('looged out');
    localStorage.removeItem("currentUser");
    this.loggedInUser = null;

    this.router.navigate(["/auth/login"]);
  }

  getLoggedInUser() {
    this.loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    return this.loggedInUser;
  }
}

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
export const InterceptorSkipHeader = "X-Skip-Interceptor";
@Injectable()
export class AuthIntercepter implements HttpInterceptor {
  constructor(
    private logingService: LoginService,
    private http: HttpClient,
    private router: Router
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      console.log("not logged in");
      //navigate /delete cookies or whatever
      // this.logingService.logOut();
      localStorage.removeItem("currentUser");
      this.router.navigateByUrl(`/auth/login/`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   // Clone the request to add the new header.
  //   const authReq = req.clone({
  //     headers: req.headers.set(Cookie.tokenKey, Cookie.getToken()),
  //   });
  //   // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
  //   return next
  //     .handle(authReq)
  //     .pipe(catchError((x) => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  // }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('Intercepted')

    if (this.logingService.getLoggedInUser() != null) {
      if (req.headers.has(InterceptorSkipHeader)) {
        const headers = req.headers.delete(InterceptorSkipHeader);
        //   return next.handle(req.clone({ headers }));
      }

      const reqCopy = req.clone({
        headers: req.headers.set(
          "Authorization",
          "Bearer " + this.logingService.getLoggedInUser().accessToken
        ),
      });
      // return next.handle(reqCopy);

      // return next
      //   .handle(reqCopy)
      //   .pipe(catchError((x) => this.handleAuthError(x)));

      return next.handle(reqCopy).pipe(
        catchError((error: any) => {
          if (error.status == 401 || error.status == 0 || error.status == 403) {
            // this.router.navigate(["/"]);
            localStorage.removeItem("currentUser");
            this.router.navigateByUrl(`/auth/login/`);
          } else {
          }
          // return of(error);
          return next.handle(reqCopy);
        })
      );
    }
    return next.handle(req);
  }
}

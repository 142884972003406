import {NgModule, Type} from '@angular/core';
import {DynamicFormsCoreModule, DYNAMIC_FORM_CONTROL_MAP_FN, DynamicFormControlModel, DynamicFormControl} from '@ng-dynamic-forms/core';

import {NG_VALIDATORS, ReactiveFormsModule, FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {InPageDjangoFormComponent} from './impl/inpage-django-form.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {DjangoFormContentComponent, externalValidator,DialogOverviewExampleDialog} from './impl/django-form-content.component';
import {DialogDjangoFormComponent} from './impl/dialog-django-form.component';
import {DjangoFormBaseComponent} from './impl/django-form-base.component';
import {DjangoFormDialogService} from './django-form-dialog.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MyDynamicCustomFormControlComponent } from './impl/ui/components/my-dynamic-custom-form-control.component';
import { MyDynamicCustomFIleUploadFormControlComponent } from './impl/ui/components/my-dynamic-custom-file-upload-form-control.component';
import { MyCustomFormControlComponent } from './impl/ui/components/my-custom-form-control.component';
import { CustomFKSelectorFormControlComponent } from './impl/ui/components/custom-fk-selector-control.component.';
import { DynamicCustomFKSelectorFormControlComponent } from './impl/ui/components/dynamic-custom-fk-selector-control.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicFormsNGBootstrapUIModule } from '@ng-dynamic-forms/ui-ng-bootstrap';
import { MyCustomFileUploadFormControlComponent } from './impl/ui/components/my-custom-file-upload-form-control.component';





@NgModule({
    declarations: [InPageDjangoFormComponent, DjangoFormContentComponent,
        DialogDjangoFormComponent, DjangoFormBaseComponent,
        MyDynamicCustomFormControlComponent,
        MyCustomFormControlComponent,
        MyCustomFileUploadFormControlComponent,
        CustomFKSelectorFormControlComponent,
        DynamicCustomFKSelectorFormControlComponent,
        MyDynamicCustomFIleUploadFormControlComponent,
        DialogOverviewExampleDialog
        
        
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicFormsCoreModule,
        MatProgressBarModule,
        MatButtonModule,
        MatSnackBarModule,
        MatDialogModule,
        NgSelectModule,
        FormsModule,
        DynamicFormsNGBootstrapUIModule,
        
    ],
    providers: [
        {provide: NG_VALIDATORS, useValue: externalValidator, multi: true},
        DjangoFormDialogService,

        {
            provide: DYNAMIC_FORM_CONTROL_MAP_FN,
            useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null  => {
        
              switch (model.type) {
                case 'MY-CUSTOM':
                  return MyDynamicCustomFormControlComponent;

                  case 'MY-CUSTOM-MODEL':
                  return MyDynamicCustomFIleUploadFormControlComponent;
                case 'FK-SELECTOR':
                  return DynamicCustomFKSelectorFormControlComponent;
                }
                
                
             }
          }

       
    ],
    exports: [InPageDjangoFormComponent, DialogDjangoFormComponent, DjangoFormBaseComponent,DialogOverviewExampleDialog],
    entryComponents: [InPageDjangoFormComponent, DialogDjangoFormComponent, DjangoFormBaseComponent,MyDynamicCustomFormControlComponent,DynamicCustomFKSelectorFormControlComponent,DialogOverviewExampleDialog,MyDynamicCustomFIleUploadFormControlComponent]
})
export class DjangoFormModule {
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-auth-logout',
  templateUrl: './auth-logout.component.html',
  styleUrls: ['./auth-logout.component.scss'],
})
export class AuthLogoutComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService) {

  }

  ngOnInit() {
    console.log('logging...');
    this.loginService.logOut();
    // this.appService.init()
    // this.router.navigate(['/'])
  }

}

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { LoggedUser } from '../../../services/dtd/loggedUser.dtd';
import { LoginService } from '../../../services/login.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit {
  error: string;
  loginForm: FormGroup;
  loggedInUser: LoggedUser;
  // reloaded = false;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private appService: AppService,
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onLogin() {
    this.error = '';
    const userName = this.loginForm.value.userName;
    const password = this.loginForm.value.password;

    this.loginService
      .login({ username: userName, password: password })
      .subscribe(
        (user: LoggedUser) => {
          this.loggedInUser = user;
          this.appService.init();

          this.navigateToUser();
        },
        (error) => {
          this.error = 'Username or Password is incorrect';
        },
      );
  }

  navigateToUser() {
    this.router.navigate(['/']);
  }

  ngAfterViewInit() {
    // window.location.reload();
    // if (!this.reloaded) {
    //   this.ngOnInit();
    // }
  }

  // ngAfter
}

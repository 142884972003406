<div *ngIf="formGroup">

    <form [formGroup]="formGroup" autocomplete="nope" (ngSubmit)="_onSubmitOnEnter()" (keydown.enter)="_onSubmitOnEnter()">

        <dynamic-ng-bootstrap-form
            [group]="formGroup"
            [model]="formModel"></dynamic-ng-bootstrap-form>

    </form>

</div>

<div class="c">
    <div class="col-sm-12">
        <div class="app-body center1">
            <main class="main d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 mx-auto">
                            <div class="card-group">
                                <div class="card p-4">
                                    <div class="card-body">
                                        <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                                            <div class="text-center">
                                                <img src="/assets/images/logo.png" class="img-avatar" alt="postman" style="width: 80px" />

                                                <p class="text-muted"><br />Sign In to your account</p>
                                            </div>

                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon-user"></i
                          ></span>
                                                </div>
                                                <input type="text" class="form-control" formControlName="userName" placeholder="Username" autocomplete="username" required />
                                            </div>
                                            <div class="input-group mb-4">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon-lock"></i
                          ></span>
                                                </div>
                                                <input type="password" class="form-control" formControlName="password" placeholder="Password" autocomplete="current-password" required />
                                            </div>

                                            <div class="text-danger text-sm">
                                                <span>{{ error }}</span>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <button type="submit" class="btn btn-primary px-4">
                            Login
                          </button>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <button type="button" class="btn btn-link px-0" [routerLink]="'/auth/reset-password'">
                            Forgot password?
                          </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card text-white bg-secondary py-5 d-md-down-none" style="width: 44%">
                                    <div class="card-body text-center">
                                        <div>
                                            <img src="/assets/images/onboarding1.png" class="img-avatar" alt="Postman" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <div class="row">
            <div class="centered">
                <ngx-footer></ngx-footer>
            </div>
        </div>
    </div>
</div>
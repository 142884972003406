import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoggedInAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, status: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    // console.log(user);

    if (user) {
      // ignore user role for now
      if (user["profile"].hasOwnProperty("password_change_required")) {
        if (user["profile"]["password_change_required"] === true) {
          this.router.navigate(["/home/profile/change-password"]);

          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    this.router.navigate(["/auth/login"]);
    return false;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  DynamicFormControlComponent,
  DynamicFormControlCustomEvent,
  DynamicFormControlLayout,
  DynamicFormLayout,
  DynamicFormLayoutService,
  DynamicFormValidationService,
} from "@ng-dynamic-forms/core";
import { CustomFKSelectorFormControlComponent } from "./custom-fk-selector-control.component.";
import { DynamicCustomFKSelectorModel } from "../models/custom-fk-selector.model";

@Component({
  selector: "dynamic-custom-fk-selector-form-control",
  changeDetection: ChangeDetectionStrategy.Default,
  template: `
    <ng-container [formGroup]="group">
      <div class="form-group">
        <label>{{ model.config.placeholder }}</label>

        <custom-fk-selector-form-control
          [formControlName]="model.id"
          [id]="bindId ? model.id : null"
          [name]="model.name"
          [model]="model"
          [ngClass]="[
            getClass('element', 'control'),
            getClass('grid', 'control')
          ]"
          (blur)="onBlur($event)"
          (change)="onChange($event)"
          (focus)="onFocus($event)"
        >
        </custom-fk-selector-form-control>
      </div>
    </ng-container>
  `,
})
export class DynamicCustomFKSelectorFormControlComponent extends DynamicFormControlComponent {
  @Input() bindId: boolean = true;
  @Input() group: FormGroup;
  @Input() layout: DynamicFormControlLayout;
  @Input() model: DynamicCustomFKSelectorModel;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output()
  customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  @ViewChild(CustomFKSelectorFormControlComponent, { static: false })
  myCustomFormControlComponent: CustomFKSelectorFormControlComponent;

  constructor(
    protected layoutService: DynamicFormLayoutService,
    protected validationService: DynamicFormValidationService
  ) {
    super(layoutService, validationService);
  }
}

import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "my-custom-form-control",
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MyCustomFormControlComponent),
      multi: true,
    },
  ],
  template: `
    <div class="image-upload">
      <img [src]="" style="max-width:300px;max-height:300px" />
      <input name="imageUrl" type="file" (change)="changeListener($event)" />
    </div>
  `,
})
export class MyCustomFormControlComponent implements ControlValueAccessor {
  @Input()
  name: string;
  propagateChange: any;
  image;

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.propagateChange(this.image);
    };
    myReader.readAsDataURL(file);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value) {
    this.image = value;
    console.log("value has been set" + value);
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthBlockComponent } from "./components/auth-block/auth-block.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { RequestPasswordComponent } from "./components/request-password/request-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { RegisterComponent } from "./components/register/register.component";
import { RouterModule } from "@angular/router";
import { MatCommonModule } from "@angular/material/core";
import { DjangoFormModule } from "../django";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatMenuModule } from "@angular/material/menu";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatExpansionModule } from "@angular/material/expansion";
import { ThemeModule } from "../@theme/theme.module";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { NbCardModule } from "@nebular/theme";
import { MatSnackBarModule } from '@angular/material/snack-bar';

export const COMMON_MODULES = [
  DjangoFormModule,
  MatCommonModule,
  // MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatMenuModule,
  NgSelectModule,
  CommonModule,
  RouterModule,
  HttpClientModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatCardModule,

  // MatListModule,
  // MatSnackBarModule,
  // MatTabsModule,
  // FlexLayoutModule,
  // HighlightJsModule,
  MatExpansionModule,
  // MatInputModule,
  ReactiveFormsModule,
  MatSliderModule,
  MatSlideToggleModule,
  FormsModule,
  ThemeModule,
  // MDBBootstrapModule,
  // TooltipModule,

  // HeaderModule,

  // MatStepperModule,
];

@NgModule({
  declarations: [
    AuthBlockComponent,
    LoginComponent,
    LogoutComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ChangePasswordComponent,
  ],
  imports: [CommonModule, RouterModule, ...COMMON_MODULES, NbCardModule],
  exports: [
    AuthBlockComponent,
    LoginComponent,
    LogoutComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ChangePasswordComponent,
  ],
})
export class AuthModule {}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoggedUser } from '../services/dtd/loggedUser.dtd';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate() {

    // return true;
    const user: any = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.router.navigate(['/']);
    }
    return true;
  }
}

import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { LoggedInAuthGuard } from "./guards/isloggedin.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AuthLogoutComponent } from "./auth-logout/auth-logout.component";
import { AuthBlockComponent } from "./auth/components/auth-block/auth-block.component";
import { LoginComponent } from "./auth/components/login/login.component";
import { RegisterComponent } from "./auth/components/register/register.component";
import { RequestPasswordComponent } from "./auth/components/request-password/request-password.component";
import { ResetPasswordComponent } from "./auth/components/reset-password/reset-password.component";
import { GeneralComponent } from "./settings/general/general.component";
import { LogOutAuthGuard } from './guards/logout.guard';

export const routes: Routes = [
  {
    // canActivate: [LoggedInAuthGuard],
    path: "home",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },

  {
    // canActivate: [AuthGuard],
    path: "auth",
    component: AuthBlockComponent,
    children: [
      {
        path: "",
        component: LoginComponent,
      },
      {
        canActivate: [AuthGuard],
        path: "login",
        component: LoginComponent,
      },
      {
        canActivate: [AuthGuard],
        path: "register",
        component: RegisterComponent,
      },
      // {
      //   canActivate: [AuthGuard],
      //   path: "logout",
      //   component: AuthLogoutComponent,
      // },
      {
        canActivate: [AuthGuard],
        path: "request-password",
        component: RequestPasswordComponent,
      },
      {
        canActivate: [AuthGuard],
        path: "reset-password",
        component: ResetPasswordComponent,
      },
      {
        canActivate: [LoggedInAuthGuard],
        path: "logout",
        component: AuthLogoutComponent,
      },
    ],
  },
  {
    canActivate: [LogOutAuthGuard],
    path: "logout",
    component: AuthLogoutComponent,
  },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "**", redirectTo: "home" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
    DynamicFileUploadModelConfig,
    DynamicFileUploadModel,
    serializable,
    DynamicFormControlLayout,
  } from '@ng-dynamic-forms/core';

  export const DYNAMIC_FORM_CONTROL_TYPE_FILE_UPLOAD = 'FILE_UPLOAD';

  export interface DynamicMyCustomModelConfig extends DynamicFileUploadModelConfig { }

  export class DynamicMyCustomModel extends DynamicFileUploadModel {
    @serializable() readonly type: string = 'MY-CUSTOM';

    constructor(config: DynamicMyCustomModelConfig, layout?: DynamicFormControlLayout) {
      super(config, layout);
    }
  }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-auth-block',
  templateUrl: './auth-block.component.html',
  styleUrls: ['./auth-block.component.scss'],
})
export class AuthBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }

  }
}

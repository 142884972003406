import { LoginService } from "./services/login.service";

// const Store = require('../../store.js');

// if (typeof localStorage === "undefined" || localStorage === null) {
//   var LocalStorage = require('node-localstorage').LocalStorage;
//   localStorage = new LocalStorage('./scratch');
// }

const local_host: string = "http://localhost:8000";
const local_host_api = local_host + "/api/v1/";

const prod_host: string = "https://api.postman.ug";
const dev_host: string = "https://api.postman.ug";

// const store = new Store({
//   // We'll call our data file 'user-preferences'
//   configName: 'user-preferences',
//   defaults: {
//     mode: 'prod',
//     // mode: 'local',

//   }
// });

function getUrl() {
  // var mode = store.get('mode');
  let mode = "prod";
  // mode = "local";
  // mode = "dev";

  console.log(mode);
  if (mode == null) {
    mode = "prod";
  }

  if (mode == "prod") {
    return prod_host;
  }
  if (mode == "dev") {
    return dev_host;
  }
  return local_host;
}

// var host = local_host

// var prod_host_api = host+"/api/v1/"

export let config = {
  apiUrl: getUrl(),
  apiRootUrl: getUrl() + "/api/v1/",
};

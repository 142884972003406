import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject, EMPTY, merge, Observable, Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ErrorService } from "./error-service";
import {
  catchError,
  filter,
  map,
  mergeMap,
  partition,
  shareReplay,
  take,
  tap,
} from "rxjs/operators";
import { DjangoFormConfig } from "./django-form-iface";
import { DjangoFormContentComponent } from "./django-form-content.component";
import { config as conf } from "../../config";

/**
 * Form component targeted on django rest framework
 */
@Component({
  selector: "django-form-base",
  template: "",
})
export class DjangoFormBaseComponent implements OnInit {
  public config$: Observable<DjangoFormConfig>;
  public errors$ = new Subject<any>();

  /**
   * Returns submitted form data
   *
   */
  @Output()
  public submit = new EventEmitter<{
    data: any;
    response?: any;
    cancel: boolean;
  }>();

  /**
   * Returns cancelled form data
   *
   */
  @Output()
  public cancel = new EventEmitter<{ data: any }>();

  @Input()
  public extraFormData: any;

  @Input()
  public formId: string;

  @Input()
  public isCustomForm: boolean;

  @Input()
  public extraConfig: any = {};

  @Output()
  valueChanged = new EventEmitter<any>();

  @ViewChild("form", { static: false })
  protected form: DjangoFormContentComponent;

  private url$ = new BehaviorSubject<string>("");
  private _config$ = new BehaviorSubject<DjangoFormConfig>({});

  constructor(
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    private errorService: ErrorService
  ) {}

  @Input()
  public initialDataTransformation: (initialData: any) => any = (x) => x;

  @Input()
  public configTransformation: (config: DjangoFormConfig) => DjangoFormConfig =
    (x) => x;

  @Input()
  public set djangoUrl(_url: string) {
    this.url$.next(_url);
  }

  @Input()
  public set config(_config: any) {
    this._config$.next(_config);
  }

  customInitialDataUrl: string;

  public ngOnInit(): void {
    console.log("on init called");

    const mergedConfigs: Observable<DjangoFormConfig> = merge(
      this.url$.pipe(
        filter((url) => !!url),
        mergeMap((url: string) => this._downloadDjangoForm(url)), // url is never null here
        shareReplay(1)
      ),
      this._config$.pipe(filter((x) => !!x))
    );

    const initialDataPartitionedConfigs = partition((x: DjangoFormConfig) => {
      // console.log(x);
      // if (x.isCustomForm) {
      //   this.customInitialDataUrl = x.customInitialDataUrl;
      // }
      return x.hasInitialData;
    })(mergedConfigs);

    this.config$ = merge(
      // if need initial data, return observable that loads them
      initialDataPartitionedConfigs[0].pipe(
        mergeMap((_config: DjangoFormConfig) =>
          this.httpClient
            .get<any>(
              this.isCustomForm
                ? (_config.customInitialDataUrl as string)
                : (_config.djangoUrl as string), // never null here
              {
                withCredentials: false,
                params: this.extraFormData,
              }
            )
            .pipe(
              catchError((error) =>
                this.errorService.showCommunicationError(error)
              ),
              map((response) => {
                // console.log(response);
                let err = response;

                let unested_response = {};
                for (let key in err) {
                  if (!this.isIterable(err[key])) {
                    // loop through all keys

                    for (let k in err[key]) {
                      unested_response[key + "__" + k] = err[key][k];
                    }
                    unested_response[key] = err[key];
                  } else {
                    unested_response[key] = err[key];
                  }
                }

                // console.log(unested_response);

                // return this.initialDataTransformation(response)

                // hack for nested
                return this.initialDataTransformation(unested_response);
              }),
              // and add the initial data as a property of the config
              map((response) => {
                // console.log(response);
                return {
                  ..._config,
                  initialData: response,
                };
              })
            )
        )
      ),
      // otherwise, just return
      initialDataPartitionedConfigs[1]
    ).pipe(
      map((config) => this.configTransformation(config)),
      tap((config) => {
        this.configLoaded(config);
      }),
      shareReplay(1)
    );
  }

  public submitted(buttonId: string, isCancel: boolean) {
    // clone the value so that button clicks are not remembered
    const value = Object.assign({}, this.form.value);
    this._flatten(null, value, null);
    if (buttonId) {
      value[buttonId] = true;
    }
    if (isCancel) {
      this.cancel.emit({ data: value });
    } else {
      this._submitToDjango(value);
    }
  }

  private _downloadDjangoForm(djangoUrl: string): Observable<DjangoFormConfig> {
    // console.log(djangoUrl);
    let djangoFormUrl = djangoUrl.split("?")[0];
    let has_params = false;
    let params = "";
    if (!this.isCustomForm) {
      if (djangoUrl.includes("?")) {
        has_params = true;
        params = djangoUrl.split("?")[1];
      }
      if (!djangoFormUrl.endsWith("/")) {
        djangoFormUrl += "/";
      }
      djangoFormUrl += "form/";
      if (this.formId) {
        djangoFormUrl += this.formId + "/";
      }
      if (has_params) {
        djangoFormUrl += "?" + params;
      }
    }

    return this.httpClient
      .get<DjangoFormConfig>(djangoFormUrl, {
        withCredentials: false,
        params: this.extraFormData,
      })
      .pipe(
        catchError((error) => this.errorService.showCommunicationError(error)),
        map((config) => ({
          djangoUrl, // add django url if not present
          ...config,
        })),
        map((config: DjangoFormConfig) => {
          config = {
            ...config,
            ...this.extraConfig,
          };
          if (config.initialData) {
            // initial data already filled, do not fill them again
            config.hasInitialData = false;
          }
          return config;
        })
      );
  }

  private _submitToDjango(data: any) {
    this.config$.pipe(take(1)).subscribe((config: DjangoFormConfig) => {
      let extra: any;
      if (this.extraFormData instanceof HttpParams) {
        extra = {};
        for (const k of this.extraFormData.keys()) {
          extra[k] = this.extraFormData.get(k);
        }
      } else {
        extra = this.extraFormData;
      }
      if (config.djangoUrl) {
        if (!config.djangoUrl.startsWith("http")) {
          config.djangoUrl = conf.apiUrl + config.djangoUrl;
        }
        let call;

        let nested = {};

        // nasty hack for nested
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            if (key.includes("__")) {
              try {
                nested[key.split("__")[0]][key.split("__")[1]] = data[key];
              } catch (err) {
                nested[key.split("__")[0]] = {
                  [key.split("__")[1]]: data[key],
                };
              }
            } else {
              nested[key] = data[key];
            }
          }
        }

        // console.log(config);
        let export_data = {};
        if (config.type == "export") {
          export_data["responseType"] = "blob";
        }
        // check for nested

        switch (config.method) {
          case "post":
            call = this.httpClient.post(
              config.djangoUrl,
              { ...extra, ...nested },

              { ...{ withCredentials: false }, ...export_data }
            );
            break;
          case "patch":
            call = this.httpClient.patch(
              config.djangoUrl,
              { ...extra, ...nested },
              { ...{ withCredentials: false }, ...export_data }
            );
            break;
          default:
            throw new Error(`Unimplemented method ${config.method}`);
        }
        call
          .pipe(
            catchError((error) => {
              if (error.status === 400) {
                let err = error.error;

                // console.log(err);
                // nasty hack for nested
                let unested_errors = {};
                for (let key in err) {
                  if (!this.isIterable(err[key])) {
                    // loop through all keys

                    for (let k in err[key]) {
                      unested_errors[key + "__" + k] = err[key][k];
                    }
                  } else {
                    unested_errors[key] = err[key];
                  }
                }

                this.errors$.next(unested_errors);

                return EMPTY;
              }
              return this.errorService.showCommunicationError(error);
            })
          )
          .subscribe((response) => {
            this.errors$.next(null);
            this.snackBar.open("Saved", "Dismiss", {
              duration: 2000,
              politeness: "polite",
            });
            this.submit.emit({
              response,
              data,
              cancel: false,
            });
          });
      } else {
        this.submit.emit({
          data,
          cancel: false,
        });
      }
    });
  }

  private isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  }

  private _flatten(name: string | null, current: any, parent: any) {
    if (current !== Object(current)) {
      return;
    }
    for (const k of Object.getOwnPropertyNames(current)) {
      const val = current[k];
      this._flatten(k, val, current);
    }
    if (name && name.startsWith("generated_")) {
      for (const k of Object.getOwnPropertyNames(current)) {
        parent[k] = current[k];
      }
      delete parent[name];
    }
  }

  protected configLoaded(config: any) {}
}

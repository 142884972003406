import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <div class="created-by socials">
      <span class="created-by">
        <a href="https://wct.co.ug">WCT</a> &copy; 2021
      </span>
      Licensed to
      <b><a href="https://postman.ug/" target="_blank">Postman App</a></b>
    </div>
  `,
})
export class FooterComponent {}

<div class="c">
    <div class="col-sm-12">
        <div class="app-body center1">
            <main class="main d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 mx-auto">
                            <div class="card-group">
                                <div class="card p-4">
                                    <div class="card-body">
                                        <div class="text-center">
                                            <img src="/assets/images/logo.png" class="img-avatar" alt="postman" style="width: 80px" />

                                            <p class="text-muted"><br />Password Reset</p>
                                        </div>
                                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                            <div class="text-center">
                                                <!-- <img
                      src="/assets/images/baridi_splash.png"
                      class="img-avatar"
                      alt="UBL"
                      style="width: 180px"
                    /> -->
                                                <p class="text-muted">
                                                    <br /> {{ help_text }}
                                                </p>
                                            </div>

                                            <div *ngIf="state == 0">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="icon-envelope"></i
                            ></span>
                                                    </div>
                                                    <input type="text" class="form-control" formControlName="userName" placeholder="Email" autocomplete="email" required />
                                                </div>
                                                <div class="text-danger text-sm">
                                                    <div *ngIf="userName.touched && userName.invalid" class="alert alert-danger">
                                                        <div *ngIf="userName.errors.required">
                                                            Email is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="state == 1">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="icon-envelope"></i
                            ></span>
                                                    </div>
                                                    <input type="text" class="form-control" formControlName="code" placeholder="Code" required />
                                                </div>
                                                <!-- <div class="text-danger text-sm">
                      <div
                        *ngIf="code.touched && code.invalid"
                        class="alert alert-danger"
                      >
                        <div *ngIf="loginForm.errors.codeRequired">
                          Code is required.
                        </div>
                      </div>
                    </div> -->
                                            </div>

                                            <div *ngIf="state == 2">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="icon-lock"></i
                            ></span>
                                                    </div>
                                                    <input type="password" class="form-control" formControlName="new_password" placeholder="New Password" required />
                                                </div>
                                                <!-- <div class="text-danger text-sm">
                      <div
                        *ngIf="new_password.touched && new_password.invalid"
                        class="alert alert-danger"
                      >
                      <div *ngIf="loginForm.errors.newPasswordRequired">
                         New Password is required.
                        </div>
                       
                      </div>
                    </div> -->
                                            </div>

                                            <div class="text-danger text-sm">
                                                <span>{{ error }}</span>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <button type="submit" class="btn btn-primary px-4">
                            Submit
                          </button>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <button type="button" class="btn btn-link px-0" [routerLink]="'/auth/login'">
                            Login
                          </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card text-white bg-secondary py-5 d-md-down-none" style="width: 44%">
                                    <div class="card-body text-center">
                                        <div>
                                            <img src="/assets/images/onboarding1.png" class="img-avatar" alt="Postman" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

        <div class="row">
            <div class="centered">
                <ngx-footer></ngx-footer>
            </div>
        </div>
    </div>
</div>
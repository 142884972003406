import { Injectable } from '@angular/core';
import { ErrorService } from '../django';
import {MatSnackBar} from '@angular/material/snack-bar';
@Injectable()
export class MatErrorService extends ErrorService {

  constructor(private service: MatSnackBar) {
      super();
  }

      showError(message, options?: {
        duration?: number
    }) {
          this.service.open(message, 'Zavřít', {
              duration: (options && options.duration) || 15000,
              politeness: 'assertive'
          });
    }
}

import { Injectable } from '@angular/core';
import { LoginService } from './services/login.service';

@Injectable()
export class AppService {
  public user: any;
  constructor(
    public loginService: LoginService,
    ) {
    this.init();
  }
  public init() {
    this.user = this.loginService.getLoggedInUser();
}
}

import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "../../../app.service";
import { DataService } from "../../../data.service";
import { LoggedUser } from "../../../services/dtd/loggedUser.dtd";
import { LoginService } from "../../../services/login.service";
import { BASE_URL } from "../../../shared/constants";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  error: string;
  loginForm: FormGroup;
  loggedInUser: LoggedUser;
  // reloaded = false;

  state = 0;
  verification_id = "";
  help_text = "Enter your email address to get a password Reset Code";

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private dataService: DataService,
    private router: Router,
    private appService: AppService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup(
      {
        userName: new FormControl("", [Validators.required]),
        code: new FormControl("", []),
        new_password: new FormControl("", []),
      }
      // [this.passwordsShouldMatch]
    );

    console.log(this.loginForm.controls);
  }
  get userName() {
    return this.loginForm.controls.userName;
  }
  get code() {
    return this.loginForm.controls["code"];
  }
  get new_password() {
    return this.loginForm.controls["new_password"];
  }

  passwordsShouldMatch(control: AbstractControl) {
    const userName = this.loginForm.value.userName;
    const code = this.loginForm.value.code;
    const new_password = this.loginForm.value.new_password;

    if (this.state == 1) {
      if (!code) {
        return { codeRequired: true };
      }
    } else {
      if (this.state == 2) {
        if (!new_password) {
          return { newPasswordRequired: true };
        }
      }
    }

    return null;
  }

  onSubmit() {
    this.loginForm.controls["userName"].setErrors(null);
    this.loginForm.controls["userName"].updateValueAndValidity();
    this.error = "";
    let userName = this.loginForm.value.userName;
    let code = this.loginForm.value.code;
    let new_password = this.loginForm.value.new_password;
    if (this.loginForm.valid) {
      let url = "/password-reset/";
      let post_data: any = {
        email: userName,
        // new_password: this.newPassword.value,
      };
      if (this.state == 1) {
        url = "/password-reset/" + this.verification_id + "/verify/";
        post_data = {
          // email: userName,
          code: code,
          // new_password: this.newPassword.value,
        };
      }
      if (this.state == 2) {
        url = "/password-reset/" + this.verification_id + "/set_password/";
        post_data = {
          new_password: new_password,
          // new_password: this.newPassword.value,
        };
      }
      this.dataService
        .postData(BASE_URL + url, post_data)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
              // A client-side or network error occurred. Handle it accordingly.
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong.

              if (error.error.hasOwnProperty("email")) {
                // this.error = true;
                this.error = error.error["email"][0];
              } else {
                // this.old_password_error = false;
              }

              if (error.error.hasOwnProperty("non_field_errors")) {
                // this.error = true;
                this.error = error.error["non_field_errors"][0];
              } else {
                // this.old_password_error = false;
              }

              if (error.error.hasOwnProperty("new_password")) {
                this.error = error.error["new_password"][0];
              } else {
                // this.new_password_error = false;
              }

              this.validateAllFormFields(this.loginForm);
            }
            // Return an observable with a user-facing error message.
            return throwError(
              "Something bad happened; please try again later."
            );
          })
        )
        .subscribe((response) => {
          if (this.state == 0) {
            this.state = 1;
            this.help_text = "Enter the reset code sent to your email";
            this.verification_id = response["id"];
          } else if (this.state == 1) {
            this.state = 2;
            this.help_text = "Create new password";
            // this.verification_id = response["id"];

            // this.snackBar.open("Password Changed", "Dismiss", {
            //   duration: 2000,
            //   politeness: "polite",
            // });

            // this.navigateToUser();
          } else if (this.state == 2) {
            this.state = 2;
            this.help_text = "Create new password";
            // this.verification_id = response["id"];

            // this.snackBar.open("Password Changed", "Dismiss", {
            //   duration: 2000,
            //   politeness: "polite",
            // });

            this.navigateToUser();
          }
        });
    } else {
      // validate all form fields
      this.validateAllFormFields(this.loginForm); // {7}
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    // {1}
    Object.keys(formGroup.controls).forEach((field) => {
      // {2}
      const control = formGroup.get(field); // {3}
      if (control instanceof FormControl) {
        // {4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        // {5}
        this.validateAllFormFields(control); // {6}
      }
    });
  }

  navigateToUser() {
    this.router.navigate(["/auth/login"]);
  }

  ngAfterViewInit() {
    // window.location.reload();
    // if (!this.reloaded) {
    //   this.ngOnInit();
    // }
  }

  // ngAfter
}
